import styled from "@emotion/styled"
import {Box, Button, Card, CardContent, CardMedia, Grid, Hidden, Tab, Tabs, Typography} from "@material-ui/core"
import {Rating} from "@material-ui/lab"
import {BsBookmarkPlus} from "@react-icons/all-files/bs/BsBookmarkPlus"
import React, {useState} from "react"
import {CircularAvatar} from "../../../components/plus/CircularAvatar"
import {
  FixedHeightImage,
  FixedHeightImg,
  FullHeightCardMedia,
  FullHeightCardWithImg,
  GrowGrid,
  SectionContainer,
  SectionPreTitle,
  SectionTitle
} from "../../../components/Shared"
import {FluidObject} from "gatsby-image"
import {ItemInfoLine, ItemInfoTextContent, ItemInfoTitle} from "../../../components/GameShared"
import {MarketplaceItem} from "../../../api/ParseObjects"

const ThumbCard = styled(Card)(({theme}) => ({
  margin: theme.spacings.sm,
  height: "80px",
  width: "100%"
}))

const FloatingIcon = styled(Box)(({theme}) => ({
  position: "absolute",
  top: "0px",
  right: "0px"
}))

const Bookmark = () => {
  return (
    <Box position={"relative"} marginBottom={"15px"}>
      <FloatingIcon>
        <BsBookmarkPlus size={25}/>
      </FloatingIcon>
    </Box>
  )
}

const MaxHeightGrid = styled(Grid)(({theme}) => ({
  height: "50vh",
  overflow: "hidden"
}))

const ThumbsGrid = styled(Grid)(({theme}) => ({
  height: "100%"
}))

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  }
}


const CTAButton = styled(Button)({
  width: "100%"
})

const StyledTab = styled(Tab)(({theme}) => ({
  ":focus,:focus-within,:hover": {
    div: {
      transform: "scale(1.1)",
      border: `3px solid ${theme.palette.secondary.main}`
    }
  },
  div: {
    transition: "transform ease 0.5s",
    border: "3px solid #00000000",
    boxSizing: "inherit",
  }
}))

export const ItemDetailsSection = (props: { item: MarketplaceItem }) => {

  const {item} = props
  const [state, setState] = useState(0)

  const changeMedia = (newValue: number) => {
    setState(newValue)
  }
  const generateTab = (type: "fluid" | "url", image: FluidObject | string, index: number) => {
    return (
      <StyledTab
        style={{minWidth: "0", flex: 0, width: "100%"}}
        wrapped={true}
        fullWidth={false}
        {...a11yProps(index)}
        disableFocusRipple
        icon={
          <ThumbCard>
            {type === "fluid" &&
            <CardMedia component={FixedHeightImage}
                       fluid={image as FluidObject}/>
            }
            {type === "url" &&
            <CardMedia component={"img"} src={image as string}/>
            }
          </ThumbCard>
        }
      />
    )
  }
  return (
    <SectionContainer>
      <SectionPreTitle>{item.category}</SectionPreTitle>
      <SectionTitle>{item.name}</SectionTitle>
      <Grid container spacing={3}>
        {/* main left */}
        <Grid item xs={12} md={9} lg={10}>
          <MaxHeightGrid container spacing={3} wrap="nowrap">
            <Hidden xsDown>
              <ThumbsGrid item xs={4} md={3} lg={2}>
                <FullHeightCardWithImg>
                  <Tabs
                    css={{height: "100%", flex: 0}}
                    orientation="vertical"
                    variant="scrollable"
                    value={state}
                    onChange={(_, val) => setState(val)}
                  >
                    {item.imagesType === "gatsby" && item.localImages?.map((image, index) => {
                      return generateTab("fluid", image!.childImageSharp!.fluid! as FluidObject, index)
                    })}
                    {item.imagesType === "parse" && item.images?.map((image, index) => {
                      return generateTab("url", image._url, index)
                    })}
                  </Tabs>
                </FullHeightCardWithImg>
              </ThumbsGrid>
            </Hidden>
            <ThumbsGrid item xs={12} sm={8} md={9} lg={10}>
              <FullHeightCardWithImg>
                <FullHeightCardMedia>
                  {item.imagesType === "gatsby" &&
                  <FixedHeightImage fluid={item.localImages![state]!.childImageSharp!.fluid as FluidObject}/>
                  }
                  {item.imagesType === "parse" &&

                  <FixedHeightImg src={item.images[state]._url}/>
                  }
                </FullHeightCardMedia>
              </FullHeightCardWithImg>
            </ThumbsGrid>
          </MaxHeightGrid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <Tabs
                  value={0}
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="item tabs"
                >
                  <Tab label="Description"/>
                  <Tab label="Comments" disabled/>
                </Tabs>
                <CardContent>
                  <Typography>{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {/* right */}
        <Grid item xs={12} md={3} lg={2}>
          <Card>
            <CardContent>
              <Bookmark/>
              <Typography variant="h6">Item Details</Typography>
              {item.file && (
                <ItemInfoLine>
                  <CTAButton href={item.file.url} variant={"outlined"}>
                    Download
                  </CTAButton>
                </ItemInfoLine>
              )}

              <Grid container>
                <GrowGrid item>
                  <ItemInfoLine>
                    <ItemInfoTitle>Note:</ItemInfoTitle>
                    <ItemInfoTextContent>
                      <Rating name="read-only" value={item.mark} readOnly size={"small"}/>
                    </ItemInfoTextContent>
                  </ItemInfoLine>
                  <ItemInfoLine>
                    <ItemInfoTitle>Added:</ItemInfoTitle>
                    <ItemInfoTextContent>{item.createdAt.toString().substring(0, 10)}</ItemInfoTextContent>
                  </ItemInfoLine>
                </GrowGrid>
                <GrowGrid item>
                  <ItemInfoLine>
                    <ItemInfoTitle>Author:</ItemInfoTitle>
                    <ItemInfoTextContent>
                      <CircularAvatar
                        value={85}
                        online={false}
                        size={25}
                        avatarUrl={item.imagesType === "gatsby" ? item.localAuthorImage!.childImageSharp!.fixed!.src : item.authorImage._url}
                      />
                      <Typography>
                        <a href={item.link!}>{item.authorName}</a>
                      </Typography>
                    </ItemInfoTextContent>
                  </ItemInfoLine>
                </GrowGrid>
              </Grid>
            </CardContent>
            <Box/>
          </Card>
        </Grid>
      </Grid>
    </SectionContainer>
  )
}
