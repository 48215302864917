import {graphql} from "gatsby"
import {MarketplaceItemFragmentFragment} from "../../../../graphql-types"
import {MarketplaceItemWithGatsbyImages} from "../../../api/ParseObjects"
// Graphql query for gatsby

export const query = graphql`
	fragment MarketplaceItemFragment on MarketplaceItem {
		name
    description
    shortdescription
		localImages {
			childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    category
    tags
    mark
    fields {
      path
    }
    createdAt
    authorName
    localAuthorImage {
			childImageSharp {
        fixed(width:50) {
          src
        }
      }
    }
    link
    file {
			url
    }
	}
`

export const toItem = (item: MarketplaceItemFragmentFragment): MarketplaceItemWithGatsbyImages => {
  return {
    imagesType: "gatsby",
    name: item.name!,
    description: item.description!,
    url: item!.fields!.path!,
    localImages: item.localImages!,
    authorName: item.authorName!,
    localAuthorImage: item.localAuthorImage!,
    mark: item.mark!,
    category: item.category!,
    createdAt: item.createdAt!,
    file: {url: item.file?.url!},
    link: item.link!,
    objectId: ""
  }
}
