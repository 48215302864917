import styled from "@emotion/styled"
import {Box, Button, Card, Grid} from "@material-ui/core"
import {BsBookmarkPlus} from "@react-icons/all-files/bs/BsBookmarkPlus"
import {graphql} from "gatsby"
import React, {useState} from "react"
import {Layout} from "../../../components/Layout"
import {ItemPageQuery} from "../../../../graphql-types"
import {toItem} from "./graphql"
import {ItemDetailsSection} from "./ItemDetailsSection"

const ThumbCard = styled(Card)(({theme}) => ({
  margin: theme.spacings.sm,
  height: "80px",
  width: "100%"
}))

const FloatingIcon = styled(Box)(({theme}) => ({
  position: "absolute",
  top: "0px",
  right: "0px"
}))

const Bookmark = () => {
  return (
    <Box position={"relative"} marginBottom={"15px"}>
      <FloatingIcon>
        <BsBookmarkPlus size={25}/>
      </FloatingIcon>
    </Box>
  )
}

const MaxHeightGrid = styled(Grid)(({theme}) => ({
  height: "50vh",
  overflow: "hidden"
}))

const ThumbsGrid = styled(Grid)(({theme}) => ({
  height: "100%"
}))

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  }
}

const ItemPage = (props: { data: ItemPageQuery }) => {

  const item = props.data.allMarketplaceItem.edges.map((_) => toItem(_.node))[0]

  const [state, setState] = useState(0)

  const changeMedia = (newValue: number) => {
    setState(newValue)
  }
  return (
    <Layout title={`Recalbox Marketplace > ${item.category} > ${item.name}`}>
      <ItemDetailsSection item={item}/>
    </Layout>
  )
}

const CTAButton = styled(Button)({
  width: "100%"
})

export default ItemPage

export const query = graphql`
	query ItemPage($objectId: String!) {
		allMarketplaceItem(filter: { objectId: { eq: $objectId } }) {
			edges {
				node {
					...MarketplaceItemFragment
				}
			}
		}
	}
`
